const mockQuestions =[
   {
      question: "Какой из следующих языков программирования является объектно-ориентированным?",
      answers: ["C", "Python", "Assembly", "Haskell"],
      rightanswer: "Python",
      id: 1
   },

   {
      question: "Какой тег используется для создания гиперссылки в HTML?",
      answers: ["<a>", "<link>", "<href>", "<url>"  ],
      rightanswer: "<a>",
      id: 2
   },
   {
      question:  "Какой метод используется для добавления элемента в конец массива в JavaScript?",
      answers: [".push()", ".pop()", ".shift()", ".unshift()"],
      rightanswer: ".push()",
      id: 3
   },
   {
      question: "Что возвращает метод indexOf в JavaScript, если элемент не найден?",
      answers: ["-1", "0", "null", "undefined"],
      rightanswer: "-1",
      id: 4
   }
]

export default mockQuestions