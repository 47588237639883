

import './App.css';
import { createBrowserRouter } from 'react-router-dom';


const router = createBrowserRouter([
  {
    
  }
])

function App() {
  return(
    <div>
      
    </div>
  );

}

export default App;
